import { Link } from 'gatsby-theme-material-ui';
import { Typography, Grid } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';

import MainLayout from '../components/MainLayout';
import MainHero from '../components/MainHero';
import Block from '../components/Block';
import Ingress from '../components/Ingress';

export function Head() {
  return <title>Veikko Mäkinen - På svenska</title>;
}

export default function Svenska() {
  return (
    <MainLayout>
      <MainHero
        title="Teknologi - Systemen - Människorna"
        subtitle="Strategi - Utveckling - Lederskap"
      />
      <Block>
        <Grid container rowSpacing={1} spacing={{ sm: 2, lg: 4 }}>
          <Grid item xs={12} sm={8} p={2}>
            <Typography variant="h4" gutterBottom>
              Hjälp till företaget och företagaren
            </Typography>
            <Ingress>
              Företaget eller företagaren själv längtar ofta efter sparrande i såväl stora som små
              frågor. Vare sig det gäller stora strategiska ärenden, taktiska val av
              datasystem eller organiserandet av vardagliga arbeten, får du betydlig nytta av en
              erfaren sparrare.
            </Ingress>
            <Ingress>
              Jag har över 20 års erfarenhet inom företagsvärldens olika områden. Till mina
              styrkor hör att hjälpa med software
              affärsverksamhet, ekonomiförvaltning och ledning, men även utvecklandet av
              försäljning, marknadsföring och kundservice står mig nära hjärtat.
            </Ingress>
            <Ingress>
              Och med min hands-on erfarenhet kan jag vara till hjälp med det egentliga arbetet
              också. Behöver du hjälp att utveckla proof-of-concept versionen av din softwareidé
              eller att designa pitch-material? Eller behöver du hjälp att analysera din ekonomiska
              data? I dessa ärenden blir du inte ensam.
            </Ingress>
            <Ingress>
              Jag betjänar gärna även svenska kunder. Jag arbetar på finska och engelska men
              med mig får du också tala svenska.
            </Ingress>
            <Ingress>
              <Link to="/contact" color="secondary">
                Hur kan jag hjälpa? Hör av dig!
              </Link>
            </Ingress>
          </Grid>
          <Grid item xs={12} sm={4} p={2}>
            <StaticImage
              src="../images/makinen-veikko-pysty.jpg"
              alt="Profile picture"
              placeholder="blurred"
              layout="constrained"
              imgStyle={{
                borderRadius: '5px',
                boxShadow: '2px 2px 5px #888',
              }}
              css={css`
                border-radius: 5px;
                box-shadow: 2px 2px 5px #888;
              `}
            />
          </Grid>
        </Grid>
      </Block>
    </MainLayout>
  );
}
